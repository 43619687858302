// Structure taken from https://css-tricks.com/creating-a-maintainable-icon-system-with-sass/
@import '../functions/str-replace';

$data-svg-prefix: 'data:image/svg+xml;utf-8,';

/**
 * Loop over icons and create classes
 */
@mixin create-icon-classes($icons) {
    @each $name, $icon in $icons {
        .icon-#{$name} {
            background-image: url($data-svg-prefix + str-replace($icon, '#', '%23'));
            background-size: cover;
        }
    }
}
