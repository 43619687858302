/**
 * Map of icon sizes
 */
$icon-sizes: 12, 16, 20, 24, 30, 40, 44, 88;

/**
 * Loop over sizes and create icons with fixed heights and widths
 */
@each $size in $icon-sizes {
    .icon-#{$size} {
        display: block; // remove extra ~3px at bottom
        min-height: $size * 1px;
        max-height: $size * 1px;
        min-width: $size * 1px;
        max-width: $size * 1px;
    }
}
